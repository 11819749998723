import { getTokenFromCookie } from '@/utils/cookies';
import { Router, scrollBehavior } from '@/utils/router.js';
import { setHtmlMeta, hostBuild } from '@/utils/utils';
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import(/* webpackChunkName: "core_house" */ './home/home.vue'),
      meta: { desc: '首页' },
    },
    {
      path: '/search',
      name: 'Search',
      component: () => import(/* webpackChunkName: "core_house" */ './search/search.vue'),
      meta: { desc: '搜索页' },
    },
    {
      path: '/list/sell',
      name: 'ListSell',
      component: () => import(/* webpackChunkName: "core_house" */ './list-sell/list-sell.vue'),
      meta: { desc: '二手房列表页' },
    },
    {
      path: '/list/rent',
      name: 'ListRent',
      component: () => import(/* webpackChunkName: "core_house" */ './list-rent/list-rent.vue'),
      meta: { desc: '租房列表页' },
    },
    {
      path: '/list/newhome',
      name: 'ListNewhome',
      component: () => import(/* webpackChunkName: "core_house" */ './list-newhome/list-newhome.vue'),
      meta: { desc: '新房/楼花列表页' },
    },
    {
      path: '/list/business',
      name: 'ListBusiness',
      component: () => import(/* webpackChunkName: "core_house" */ './list-business/list-business.vue'),
      meta: { desc: '商铺/写字楼列表页' },
    },
    {
      path: '/list/agent',
      name: 'ListAgent',
      component: () => import(/* webpackChunkName: "core_house" */ './list-agent/list-agent.vue'),
      meta: { desc: '经纪人列表页' },
    },
    {
      path: '/detail/sell/:id',
      name: 'DetailSell',
      component: () => import(/* webpackChunkName: "core_house" */ './detail-sell/detail-sell.vue'),
      meta: { desc: '二手房详情页' },
    },
    {
      path: '/detail/rent/:id',
      name: 'DetailRent',
      component: () => import(/* webpackChunkName: "core_house" */ './detail-rent/detail-rent.vue'),
      meta: { desc: '租房详情页' },
    },
    {
      path: '/detail/newhome/:id',
      name: 'DetailNewhome',
      component: () => import(/* webpackChunkName: "core_house" */ './detail-newhome/detail-newhome.vue'),
      meta: { desc: '新房/楼花详情页' },
    },
    {
      path: '/detail/business/:id',
      name: 'DetailBusiness',
      component: () => import(/* webpackChunkName: "core_house" */ './detail-business/detail-business.vue'),
      meta: { desc: '商铺/写字楼详情页' },
    },
    {
      path: '/publish/sell',
      name: 'PublishSell',
      component: () => import(/* webpackChunkName: "core_house" */ './publish-sell/publish-sell.vue'),
      meta: { desc: '二手房发布页', isAuth: true },
    },
    {
      path: '/publish/rent',
      name: 'PublishRent',
      component: () => import(/* webpackChunkName: "core_house" */ './publish-rent/publish-rent.vue'),
      meta: { desc: '租房发布页', isAuth: true },
    },
    {
      path: '/publish/newhome',
      name: 'PublishNewhome',
      component: () => import(/* webpackChunkName: "core_house" */ './publish-newhome/publish-newhome.vue'),
      meta: { desc: '新房/楼花发布页', isAuth: true },
    },
    {
      path: '/publish/business',
      name: 'PublishBusiness',
      component: () => import(/* webpackChunkName: "core_house" */ './publish-business/publish-business.vue'),
      meta: { desc: '商铺/写字楼发布页', isAuth: true },
    },
    {
      path: '/agent/auth',
      name: 'AgentAuth',
      component: () => import(/* webpackChunkName: "core_house" */ './agent-auth/agent-auth.vue'),
      meta: { desc: '经纪人登记页面', isAuth: true },
    },
    {
      path: '/agent/:id',
      name: 'AgentHome',
      component: () => import(/* webpackChunkName: "core_house" */ './agent/agent.vue'),
      meta: { desc: '经纪人详情页' },
    },
    {
      path: '/websafe',
      name: 'WebSafe',
      component: () => import(/* webpackChunkName: "core_house" */ '@/common/websafe.vue'),
    },
    {
      path: '/404',
      name: 'NotFound',
      component: () => import(/* webpackChunkName: "core_house" */ '@/common/404/index.vue'),
      meta: { desc: '404' },
    },
    {
      path: '*',
      redirect: '/404',
    },
  ],
  scrollBehavior,
});

router.beforeEach((to, from, next) => {
  // 这里只能用本地token进行判断是否登录，否则刷新页面会出现问题
  const token = getTokenFromCookie();
  if (to.meta.isAuth === true && !token) {
    location.replace(hostBuild(`/login?redirect=${location.href}`, 'account'));
    return next(false);
  }

  if (to.name == 'Home') {
    setHtmlMeta({
      title: '多伦多房产 - 约克论坛 - 加拿大第一中文网',
      keywords:
        '多伦多租房,多伦多房产,加拿大房产,多伦多楼花,大多伦多房价,condo,apartment, Toronro housing,Toronto house listing,Toronto open house,Toronto rent',
      description:
        '提供多伦多租房、多伦多楼花、二手房买卖、condo、apartment、商铺转让、地产经纪和地产公司等房源租售信息，华人和留学生免费找房或发布房源信息，就到约克论坛。yorkbbs.ca',
    });
  } else if (to.name == 'ListRent') {
    setHtmlMeta({
      title: '多伦多租房 - 多伦多房产 - 约克论坛',
      keywords: '多伦多房屋出租,多伦多求租,学生合租,homestay,多伦多短租,家庭住宿,留学生租房,toronto house condo rental',
      description:
        '为华人和留学生提供多伦多、Markham、Richmond Hill、North York、Scarborough、Etobicoke、Mississauga等地房屋出租、房屋求租、留学生homestay、学生合租、房东直租和经纪租房等房源信息。yorkbbs.ca',
    });
  } else if (to.name == 'ListNewhome') {
    setHtmlMeta({
      title: '多伦多楼花 - 多伦多房产 - 约克论坛',
      keywords:
        '多伦多楼花,多伦多新房,condo,公寓楼花,Detached house,独立屋楼花,townhouse,镇屋楼花,Semi-detached,半独立屋楼花,房产投资,condo 投资,楼花转让,MLS,学区房',
      description:
        '汇集多伦多、Markham、Richmond Hill、North York、Scarborough、Etobicoke、Mississauga等地楼花、condo、detached house、独立屋楼花、镇屋楼花、半独立屋楼花等全面优质楼花买卖和转让信息。yorkbbs.ca',
    });
  } else if (to.name == 'ListSell') {
    setHtmlMeta({
      title: '多伦多二手房 - 多伦多房产 - 约克论坛',
      keywords: '多伦多二手房,豪宅,Detached house,独立屋,townhouse,镇屋,Semi-detached,半独立屋condo,公寓,MLS,学区房',
      description:
        '提供多伦多、Markham、Richmond Hill、North York、Scarborough、Etobicoke、Mississauga等地二手房、Detached house、独立屋、townhouse、镇屋、Semi-detached、半独立屋，condo等多种类房源信息！yorkbbs.ca。',
    });
  } else if (to.name == 'ListAgent') {
    setHtmlMeta({
      title: '多伦多房产经纪 - 多伦多房产 - 约克论坛',
      keywords:
        '多伦多房产经纪,地产经纪,金牌经纪,楼花专家,broker,agent,专业地产顾问,broker,sales representative,楼花专家,MLS,学区房',
      description:
        '多伦多专业房产经纪，金牌经纪，楼花专家、broker、agent，专业地产顾问等优秀地产界专家，发布房源信息，为多伦多华人、新移民及留学生提供找房看房咨询！yorkbbs.ca',
    });
  } else if (to.name == 'ListBusiness') {
    setHtmlMeta({
      title: '多伦多商铺/写字楼 - 多伦多房产 - 约克论坛',
      keywords: '多伦多商铺,多伦多写字楼,旺铺转让,车位出租,多伦多店铺商铺出租,商铺投资,Toronto商铺出售,多伦多店面出租求租,店铺投资转让,',
      description:
        '约克论坛房产提供多伦多商铺、多伦多写字楼、店铺投资、旺铺转让及车位出租等商业房产出租出售信息，汇集Markham、Richmond Hill、North York、Scarborough、Etobicoke、Mississauga等地商铺信息，是多伦多华人商业地产投资好帮手。yorkbbs.ca',
    });
  } else if (to.name == 'AgentAuth') {
    setHtmlMeta({
      title: '经纪人 - 发布信息 - 多伦多房产 - 约克论坛',
      keywords: '',
      description: '',
    });
  } else if (to.name == 'PublishSell') {
    setHtmlMeta({
      title: '二手房 - 发布信息 - 多伦多房产 - 约克论坛',
      keywords: '',
      description: '',
    });
  } else if (to.name == 'PublishRent') {
    setHtmlMeta({
      title: '租房 - 发布信息 - 多伦多房产 - 约克论坛',
      keywords: '',
      description: '',
    });
  } else if (to.name == 'PublishNewhome') {
    setHtmlMeta({
      title: '新房/楼花 - 发布信息 - 多伦多房产 - 约克论坛',
      keywords: '',
      description: '',
    });
  } else if (to.name == 'PublishBusiness') {
    setHtmlMeta({
      title: '商铺/写字楼 - 发布信息 - 多伦多房产 - 约克论坛',
      keywords: '',
      description: '',
    });
  } else if (to.name == 'Search') {
    setHtmlMeta({
      title: '搜索 - 多伦多房产 - 约克论坛',
      keywords: '',
      description: '',
    });
  } else if (to.name == 'WebSafe') {
    setHtmlMeta({ title: '外链 - 约克论坛', keywords: '', description: '' });
  } else if (to.name == 'NotFound') {
    setHtmlMeta({ title: '404 - 约克论坛', keywords: '', description: '' });
  } else {
    setHtmlMeta({ title: '', keywords: '', description: '' });
  }

  if (to.name == 'NotFound' && !to.query.from) return next({ name: 'NotFound', query: { from: location.href } });

  next();
});

export { router };
