import Vue from 'vue';
import Vuex from 'vuex';
import { commonStore } from '@/utils/store.js';
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    ...commonStore.state,
    // 版块列表
    _topics: [
      {
        id: '53aa444d-487e-4845-b6dc-57dca6637bfa',
        name: '二手房',
        englishName: 'sell',
      },
      {
        id: '4c0634ea-b936-4f60-9be4-efdb0e55c6cd',
        name: '租房',
        englishName: 'rent',
      },
      {
        id: 'cea4fd8b-07f7-4665-a983-f40e26babd61',
        name: '新房/楼花',
        englishName: 'newhome',
      },
      {
        id: 'a071415f-4dd9-4a87-a21e-728d356d9670',
        name: '商铺/写字楼',
        englishName: 'business',
      },
    ],
  },
  getters: {
    ...commonStore.getters,
    // 版块列表
    topics(state) {
      return state._topics;
    },
    // 版块对象
    topicMapping(state) {
      return state._topics.reduce((acc, v) => ({ ...acc, [v.englishName]: v }), {});
    },
  },
  mutations: {
    ...commonStore.mutations,
  },
  actions: {
    ...commonStore.actions,
  },
});

export { store };
